import DatePicker from 'react-date-picker'
import { Value } from 'react-date-picker/dist/cjs/shared/types'
import 'react-date-picker/dist/DatePicker.css'
import 'gijgo/css/gijgo.min.css'

export interface DatePickerInputProps {
  isOpen: boolean
  toggleDatePicker: () => void
  selectedDate: Date
  onDateChange: (date: Value) => void
  minDate?: Date
  maxDate?: Date
}
const DatePickerInput = ({
  isOpen,
  toggleDatePicker,
  selectedDate,
  onDateChange,
  minDate,
  maxDate
}: DatePickerInputProps) => {
  return (
    <div className="form-group mb-3 datepicker-container">
      <div className="input-group mb-3">
        <DatePicker
          className={'form-control'}
          isOpen={isOpen}
          value={selectedDate}
          onChange={onDateChange}
          calendarIcon={null}
          clearIcon={null}
          minDate={minDate}
          maxDate={maxDate}
        />
        <button
          className="btn btn-outline-secondary border-left-0"
          type="button"
          onClick={toggleDatePicker}
        >
          <i className="gj-icon">event</i>
        </button>
      </div>
    </div>
  )
}
export default DatePickerInput
