import { BetGameItem } from 'redux/saga/fetchGameListSaga'

/**
 * Generates a checksum for bet validation
 * @param betList List of bets
 * @param playerId Player ID
 * @param roundId Round ID
 * @returns Checksum string
 * @throws Error if playerId is missing
 */
export const generateBetChecksum = async (
  betList: BetGameItem[],
  playerId: number | null | undefined,
  roundId: number
): Promise<string> => {
  if (!playerId) {
    throw new Error('Player ID is required for bet checksum generation')
  }

  const betHashes = betList.map(
    (bet) => `${bet.type}-${bet.number}-${bet.price.toFixed(2)}`
  )

  betHashes.sort()

  const fullData = `${playerId}-${roundId}-${betHashes.join(',')}`

  // Convert string to bytes
  const msgBuffer = new TextEncoder().encode(fullData)

  // Generate SHA-256 hash using Web Crypto API
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // Convert buffer to hex string (same as Go's hex.EncodeToString)
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}
