import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducers'
import { PayloadFetchTicketDetail } from 'redux/saga/fetchGameListSaga'
import { BetDetailCard, GenericWidget, PollStatus } from 'components'
import { sagaActions } from 'redux/sagaActions'
import { PollItemProps, WinStatus } from 'components/PollItem'
import {
  PayloadBetCancel,
  PayloadFetchTickets
} from 'redux/saga/fetchGameListSaga'
import dayjs, {
  DATE_TIME_FORMAT_HUMAN,
  convertToDateTime
} from 'utils/date-time-utils'
import { useNavigate } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { Game, Round, Ticket, TicketDetail } from 'redux/reducers/gameReducer'
import { usePagination } from 'components/TablePagination/usePagination'
import { NumberFormat } from 'utils/number-utils'
import { Column, TableProps } from 'components/TablePagination'
import {
  getLottoConfig,
  isEncryptLotto,
  isYeekeeType
} from 'utils/lotto-type-utils'
export const POLL_RECENT_TYPE = 'waiting,approved'
export const POLL_RESULTED_TYPE = 'settled'
export const POLL_CANCELED = 'cancelled,rejected'
export const POLL_HISTORY = 'history'

const onHandleCancel = () => {
  const dispatch = useDispatch()
  const onCancel = (betId: number) => {
    const payload: PayloadBetCancel = {
      betId
    }
    dispatch({ type: sagaActions.POST_BET_CANCEL, payload })
  }
  const checkCanCancel = (ticket?: Ticket) => {
    if (!ticket) {
      return false
    }
    const ticketCreationTime = dayjs(ticket?.created_at)
    const currentTime = dayjs()
    const duration = currentTime.diff(ticketCreationTime, 'minutes')
    const closed =
      ticket.lotto_round_game &&
      dayjs(ticket?.lotto_round_game?.close_at).isBefore(currentTime)
    const canCancel =
      duration <= 15 && ticket?.lotto_round_game?.is_enable === 'yes' && !closed
    return canCancel
  }
  return { onCancel, checkCanCancel }
}

export const usePollDetail = (ticketId: number, isHistory?: boolean) => {
  const { t, i18n } = useTranslation()
  const navigation = useNavigate()
  const { pollDetails, loading, response } = useSelector(
    (state: RootState) => ({
      pollDetails: state.games.ticketDetails,
      loading: state.games.loading.ticketDetails,
      response: state.games.response
    })
  )
  const onClickCheckResult = () => {
    navigation(
      `/lotto/${pollDetails?.lotto_game?.type}/${pollDetails?.lotto_game?.id}/${pollDetails?.lotto_round_game?.id}`
    )
  }
  useEffect(() => {
    if (response && response?.status === 'OK') {
      toast.success(
        t('message.successCancelBet', { pollNumber: response?.data?.id })
      )
      dispatch({ type: sagaActions.CLEAR_RESPONSE })
      navigation('/lotto/poll')
    }
    if (response && response.status !== 'OK') {
      toast.error(response.message)
      dispatch({ type: sagaActions.CLEAR_RESPONSE })
    }
  }, [response])
  const getDetailsName = (game?: Game, round?: Round) => {
    const gameDate = round?.close_at
      ? convertToDateTime(round.close_at, DATE_TIME_FORMAT_HUMAN, i18n.language)
      : '-'
    if (!game || !round) {
      return '-'
    }
    const name = i18n.language === 'th' ? game?.name_th : game?.name_en ?? '-'
    const isShowRound = isYeekeeType(game?.type) || isEncryptLotto(game?.type)
    if (isShowRound) {
      return `${name}#${round?.round_number}`
    }
    return `${name}#${gameDate}`
  }
  const { checkCanCancel, onCancel } = onHandleCancel()
  const handleCancel = () => {
    if (!pollDetails) {
      return
    }
    const betId = pollDetails?.id
    toastr.confirm(t('message.confirmCancelBet', { pollNumber: betId }), {
      onOk: () => onCancel(betId)
    })
  }
  const configs = getLottoConfig(pollDetails?.lotto_round_game)
  const dispatch = useDispatch()
  const fetchData = () => {
    const payload: PayloadFetchTicketDetail = {
      ticketId
    }
    console.log(isHistory)
    if (isHistory) {
      dispatch({ type: sagaActions.FETCH_TICKET_DETAIL_HISTORY, payload })
      return
    }
    dispatch({ type: sagaActions.FETCH_TICKET_DETAIL, payload })
  }
  let grouped: { [key: string]: TicketDetail[] } = {}
  if (pollDetails?.details) {
    grouped = pollDetails?.details.reduce(
      (result, ticket) => {
        const { type } = ticket
        if (!result[type]) {
          result[type] = []
        }
        result[type].push(ticket)
        return result
      },
      {} as { [key: string]: TicketDetail[] }
    )
  }
  const ticketName = getDetailsName(
    pollDetails?.lotto_game,
    pollDetails?.lotto_round_game
  )
  const columns: Column[] = [
    {
      name: t('pollDetails.betList'),
      accessor: 'betList',
      tdClass: 'bet-number-container',
      Cell: (row: TicketDetail) => (
        <>
          <span className="bet-number">{row.number}</span>
          {'   '}
          <span className="bet-number-amount">
            {NumberFormat(row.bet_amount)}
          </span>
        </>
      )
    },
    {
      name: t('pollDetails.pay'),
      accessor: 'reward_result',
      Cell: (row: TicketDetail) => {
        const reward = configs.find((c) => c.type === row.type)?.reward ?? 0
        let limitReward = 0
        if (reward != row.reward_amount) {
          limitReward = row.reward_amount
        }
        return (
          <span>
            {' '}
            {NumberFormat(reward)}{' '}
            {limitReward > 0 && (
              <span style={{ color: 'green' }}>
                ({NumberFormat(limitReward)})
              </span>
            )}
          </span>
        )
      },
      tdStyle: { verticalAlign: 'middle' }
    },
    {
      name: t('pollDetails.status'),
      accessor: 'status',
      Cell: (row: TicketDetail) => {
        let status = row?.status ?? ''
        if (pollDetails?.status === 'cancelled') {
          status = 'cancelled'
        }
        if (pollDetails?.status === 'settled') {
          return (
            <WinStatus isWin={row?.reward_result > 0}>
              {row?.reward_result > 0
                ? NumberFormat(row?.reward_result)
                : t('message.lost')}
            </WinStatus>
          )
        }
        return <PollStatus status={status} />
      }
    }
  ]
  const renderElementTable = () => {
    const elements: React.ReactNode[] = []
    Object.keys(grouped).map((key) => {
      const tableProps: TableProps = {
        columns,
        data: grouped[key],
        tableClassName: 'table-bet-lotto-list'
      }
      if (grouped[key].length > 0)
        elements.push(
          <BetDetailCard key={key}>
            <h3> {t(`betDetailPanel.betLottoType.${key}`)}</h3>
            <GenericWidget component="GenericTable" props={tableProps} />
          </BetDetailCard>
        )
    })
    return elements
  }
  useEffect(() => {
    fetchData()
  }, [])
  return {
    canCancel: checkCanCancel(pollDetails),
    onCancel: handleCancel,
    pollDetails,
    loading,
    fetchData,
    grouped,
    renderElementTable,
    ticketName,
    onClickCheckResult
  }
}

export const usePoll = (ticketType: string) => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { page, limit, onPageChange } = usePagination('created_at DESC', 10)
  const { t, i18n } = useTranslation()

  const { response, loading, listTicket, count } = useSelector(
    (state: RootState) => ({
      response: state.games.response,
      loading: state.games.loading.listTicket,
      listTicket: state.games.listTicket,
      count: state.games.listTicket?.count ?? 0
    })
  )
  const getDetailsName = (game?: Game, round?: Round) => {
    const gameDate = round?.close_at
      ? convertToDateTime(round.close_at, DATE_TIME_FORMAT_HUMAN, i18n.language)
      : '-'
    if (!game || !round) {
      return '-'
    }
    const showRound = isYeekeeType(game?.type) || isEncryptLotto(game?.type)
    const name = i18n.language === 'th' ? game?.name_th : game?.name_en ?? '-'
    if (showRound) {
      return `${name}#${round?.round_number}`
    }
    return `${name}#${gameDate}`
  }
  const { checkCanCancel, onCancel } = onHandleCancel()
  const tickets =
    listTicket?.tickets?.map((ticket) => {
      const canCancel = checkCanCancel(ticket)
      const item: PollItemProps = {
        canCancel,
        isPrimary: ticket.is_primary,
        betAmount: ticket.bet_amount,
        status: ticket.status,
        gameDate: convertToDateTime(
          ticket.created_at,
          DATE_TIME_FORMAT_HUMAN,
          localStorage.getItem('lang') ?? i18n.language
        ),
        details: getDetailsName(ticket.lotto_game, ticket.lotto_round_game),
        pollNumber: ticket.id.toString(),
        winAmount: ticket.win_amount,
        onClickDetail: () => {
          if (ticketType === POLL_HISTORY) {
            navigation(`history/${ticket.id}`)
            return
          }
          navigation(`${ticket.id}`)
        },
        bonus: ticket.bonus
      }
      if (ticketType != POLL_RESULTED_TYPE && ticketType != POLL_HISTORY) {
        item.onCancel = () => {
          toastr.confirm(
            t('message.confirmCancelBet', { pollNumber: ticket.id }),
            {
              onOk: () => onCancel(ticket.id)
            }
          )
        }
      }
      return item
    }) ?? []

  const fetchData = () => {
    const payload: PayloadFetchTickets = {
      page: page ?? 1,
      pageSize: limit,
      ticketType
    }
    console.log('TicketType', ticketType)
    if (ticketType !== POLL_HISTORY) {
      dispatch({ type: sagaActions.FETCH_TICKET_PAGINATION, payload })
    } else {
      dispatch({ type: sagaActions.FETCH_TICKET_PAGINATION_HISTORY, payload })
    }
    dispatch({ type: sagaActions.CLEAR_RESPONSE })
  }

  useEffect(() => {
    if (response && response?.status === 'OK') {
      toast.success(
        t('message.successCancelBet', { pollNumber: response?.data?.id })
      )
      dispatch({ type: sagaActions.CLEAR_RESPONSE })
    }
    if (response && response.status !== 'OK') {
      toast.error(response.message)
      dispatch({ type: sagaActions.CLEAR_RESPONSE })
    }
    fetchData()
  }, [response, page])

  return {
    tickets,
    onCancel,
    loading,
    page,
    limit,
    onPageChange,
    listTicket,
    count
  }
}
