import { WinStatus } from 'components/PollItem'
import PollStatus from 'components/PollStatus'
import { useTranslation } from 'react-i18next'
import { Ticket } from 'redux/reducers/gameReducer'
import {
  DATE_TIME_FORMAT_HUMAN,
  convertToDateTime
} from 'utils/date-time-utils'
import { NumberFormat } from 'utils/number-utils'

type BetDetailInfoProps = {
  ticketName?: string
  ticket?: Ticket
  canCancel: boolean
  onClickCheckResult?: () => void
  onCancel?: () => void
}
const BetDetailInfo = ({
  ticket,
  ticketName,
  canCancel,
  onCancel,
  onClickCheckResult
}: BetDetailInfoProps) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <h2 className="bet-list-number p-2 mb-3">
        {t('pollItem.pollNumber')}: <span>#{ticket?.id}</span>
      </h2>
      <div className="row bet-number-list-detail">
        <div className="col-12 col-md-6 col-lg-6">
          <p onClick={onClickCheckResult} style={{ cursor: 'pointer' }}>
            <strong>{t('pollItem.list')}</strong>
            <span>{ticketName ?? '-'}</span>
          </p>
          <p>
            <strong>{t('pollItem.betAmount')}</strong>
            <span>{NumberFormat(ticket?.bet_amount ?? 0)}</span>
          </p>
          <p>
            <strong>{t('pollItem.status')}</strong>
            <PollStatus status={ticket?.status ?? ''} />
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <p>
            <strong>{t('pollItem.betDate')}</strong>
            <span>
              {convertToDateTime(
                ticket?.created_at ?? '',
                DATE_TIME_FORMAT_HUMAN,
                localStorage.getItem('lang') ?? i18n.language
              )}
            </span>
          </p>
          <p>
            <strong>{t('pollItem.winAmount')}</strong>
            <WinStatus isWin={(ticket?.win_amount ?? 0) > 0}>
              {NumberFormat(ticket?.win_amount ?? 0)}
            </WinStatus>
          </p>
          {ticket?.is_primary &&
            ticket.status === 'settled' &&
            ticket.bonus > 0 && (
              <p>
                <strong>{t('pollItem.bonus')}</strong>
                <span>{NumberFormat(ticket.bonus)}</span>
              </p>
            )}
          {['approved'].includes(ticket?.status ?? '') && canCancel && (
            <p className="mb-2">
              <a
                title={t('pollItem.cancel').toString()}
                className="text-red"
                onClick={() => onCancel?.()}
              >
                {t('pollItem.cancel')}
              </a>
            </p>
          )}
        </div>
      </div>
    </>
  )
}
export default BetDetailInfo
