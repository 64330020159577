import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import ReduxToastr from 'react-redux-toastr'
import { Toaster } from 'react-hot-toast'
import * as Sentry from '@sentry/react'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  environment: `${process.env.REACT_APP_ENV}`,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay()
  ],

  ignoreErrors: [
    // Error from Chrome. Ignore until issue solved. Ref: https://issuetracker.google.com/issues/396043331
    "Can't find variable: gmo",
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

// Sentry.captureException("TESTING SENTRY");

root.render(
  <Sentry.ErrorBoundary>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <ReduxToastr
          timeOut={4000}
          newestOnTop
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <Toaster position="top-right" />
      </Provider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
