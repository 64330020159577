import routes from 'routes'
// import { HelmetProvider } from 'react-helmet-async'
import { useRoutes } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sagaActions } from 'redux/sagaActions'
import { useEffect } from 'react'
import i18n from 'i18n'
import 'styles/styles.css'
import { RootState } from 'redux/reducers'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

function App() {
  const content = useRoutes(routes)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  if (process.env.REACT_APP_ENV !== 'local') {
    // console.log = function () {
    //   return
    // }
    // console.error = function () {
    //   return
    // }
    // console.warn = function () {
    //   return
    // }
  }

  const targetPath = useSelector((state: RootState) => {
    return state.router?.navigatePath
  })

  const targetDelay = useSelector((state: RootState) => {
    return state.router?.delay ? state.router.delay : 0
  })

  const globalError = useSelector((state: RootState) => state.error.globalError)
  const title = useSelector((state: RootState) => state.user?.title)
  const faviconUrl = useSelector((state: RootState) => state.user?.faviconUrl)

  useEffect(() => {
    document.title = title ? title : 'RB7 Lotto'
  }, [title])

  useEffect(() => {
    // const link = document.getElementById('favicon')
    const linkElement = document.querySelector("link[rel='icon']")
    if (linkElement)
      linkElement.setAttribute('href', faviconUrl ? faviconUrl : '/favicon.ico')
    console.log('linkElement', linkElement)
    // console.log('link', link)
    // if (!link) {
    //   link = document.createElement('link')
    //   if (link) link.rel = 'icon'
    //   document.getElementsByTagName('head')[0].appendChild(link)
    // }
    // link.href = faviconUrl
  }, [faviconUrl])

  useEffect(() => {
    if (globalError?.code === 404) {
      navigate('/404')
    }
    if (globalError?.code === 401) {
      navigate('/session_error')
    }
  }, [globalError])

  useEffect(() => {
    dispatch({
      type: sagaActions.INITIALIZE_APP,
      payload: {
        location: location
      }
    })
  }, [])

  useEffect(() => {
    console.log('Component didmount: APP TSX')
    const timeOut = targetDelay

    if (targetPath) {
      setTimeout(() => {
        navigate(targetPath)
      }, timeOut)
    }
  }, [targetPath])

  return <I18nextProvider i18n={i18n}>{content}</I18nextProvider>
}

export default App
